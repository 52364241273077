/*--
/*  5.2 - Sidebar Widget CSS
/*----------------------------------------*/


// Sidebar Widget 
.sidebar-widget{
    padding-top: 20px;
}

// Categories Widget 
.categories-widget{
    margin-top: 30px;

    & .category-top-menu{
        list-style: none;
        padding: 0;
        margin: 0;

        & > li{
            & > a{
                font-size: 20px;
                line-height: 1;
                font-weight: 700;
                color: $dark;
                margin-bottom: 20px;
                position: relative;
                display: block;
                padding-bottom: 15px;
                text-transform: capitalize;
                text-decoration: none;
            }
        }
        & .category-sub-menu{
            list-style: none;
            padding: 0;
            margin: 0;

            & li{
                position: relative;
                
                & a{
                    font-weight: 400;
                    color: $dark;
                    display: block;
                    font-size: 14px;
                    text-transform: capitalize;
                    line-height: 34px;
                    margin: 0;
                    padding: 0;
                    border: none;      
                    text-decoration: none;
                    position: relative;
                    transition: all 0.3s linear;

                    & span{
                        float: right;
                        display: block;
                    }

                    &:hover{
                        color: $primary;
                    }
                }
                & .category-toggle{
                    width: 28px;
                    height: 28px;
                    font-size: 18px;
                    color: $dark;
                    position: absolute;
                    top: 2px;
                    right: 0;
                    cursor: pointer;

                    & .add,
                    & .remove{
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        text-align: center;
                        line-height: 28px;
                    }
                    & .add{
                        display: none;
                    }

                    &.collapsed{
                        & .remove{
                            display: none;
                        }
                        & .add{
                            display: block;
                        }
                    }
                }

                & ul{
                    padding-left: 5%;
                    & li{
                        & ul{
                            padding-left: 10%;        
                            & li{
                                & ul{
                                    padding-left: 15%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Filter Widget 
.filter-widget{
    margin-top: 30px;
}

// Filter Title
.filter-title{
    font-size: 20px;
    line-height: 1;
    font-weight: 700;
    color: #242424;
    position: relative;
    display: block;
    padding-top: 55px;
    border-top: 1px solid #e5e5e5;
    text-transform: uppercase;
    margin-bottom: 0; 
}

// Widget Title
.widget-title{
    display: block;
    clear: both;
    color: #242424;
    font-weight: 700;
    font-size: 20px;
    padding: 0;
    margin-bottom: 35px;
    line-height: 24px;
    text-transform: capitalize;
    margin-bottom: 0;
}

// Widget Item
.widget-item{
    padding-top: 30px;

    & ul{
        list-style: none;
        padding: 0;
        margin: 0;
        padding-top: 20px;

        & li{          
            margin-top: 22px;

            & > label{
                display: flex;
                align-items: center;

                & .form-check{
                    padding: 0;
                    display: inline-block;
                    min-height: inherit;
                    margin-bottom: 0;

                    & .form-check-input{
                        margin: 0;
                        width: 15px;
                        height: 15px;
                        border-radius: 3px;
                        // box-shadow: 2px 2px 4px 0 rgba($black,.2);
                    }
                }
                & a{
                    display: block;
                    text-decoration: none;
                    flex: 1;
                    padding-left: 7px;
                    color: #242424;
                    font-size: 14px;
                }
            }
        }
    }

    & .widget-price{
        padding-top: 38px;

        & input{
            font-size: 14px;
            color: $dark;
            width: 100%;
            padding: 0;
            border: 0;
            background: none;
        }

        & .ui-slider {
            height: 6px;
            margin-top: 15px;
            margin-bottom: 6px;

            & .ui-slider-range{
                background-color: $primary;
                border-color: $primary;
            }
            & .ui-slider-handle{
                border: 1px solid #555;
                width: 6px;
                height: 18px;
                top: -7px;
                margin-left: -4px;

                &.ui-state-active{
                    background-color: $white;
                }
            }
        }
    }
}

// Widget Tags
.widget-tags{
    padding-top: 45px;
    border-top: 1px solid #ebebeb;
    margin-top: 30px;

    & .widget-tags-items{
        margin-left: -5px;
        margin-right: -5px;

        & a{
            display: block;
            float: left;
            padding: 0 25px;
            font-size: 14px;
            line-height: 38px;
            color: $dark;
            font-weight: 400;
            border: 1px solid #ebebeb;
            border-radius: 25px;
            margin: 10px 5px 0;
            text-transform: capitalize;  
            text-decoration: none;     
            transition: all 0.3s linear;
            
            &:hover{
                background-color: $primary;
                border-color: $primary;
                color: $white;
            }
        }
    }
}

// Widget Search 
.widget-search{
    & .single-form{
        position: relative;

        & .form-control{
            padding-right: 45px;
        }

        & button{
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            height: 50px;
            border: 0;
            background: none;
            font-size: 18px;
            color: $dark;
        }
    }
}

// Widget Post 
.widget-post{
    margin-top: 25px;

    & .widget-post-items{
        padding: 0;
        margin: 0;
        list-style: none;
        padding-top: 15px;

        & li{}
    }

    & .single-post-item{
        display: flex;
        margin-top: 30px;

        & .post-thumb{
            & a{
                & img{
                    width: 90px;
                }
            }
        }
        & .post-content{
            flex: 1;
            padding-left: 20px;

            & .post-title{
                & a{
                    font-size: 14px;
                    color: $dark;
                    text-decoration: none;
                    transition: all 0.3s linear;
                    font-weight: 600;

                    &:hover{
                        color: $primary;
                    }
                }
            }
            & .date{
                font-size: 13px;
                color: #707070;
            }
        }
    }
}