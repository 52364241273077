/*--
/*  4.15 - Login & Register CSS
/*----------------------------------------*/


.login-register-wrapper{
    & .title{
        font-size: 24px;
        font-weight: 700;
        color: $dark;
    }

    & .form-check{
        margin-bottom: 0;
        min-height: inherit;
        margin-top: 30px;

        & .form-check-input{
            margin-top: 2px;
        }
    }

    & .single-form{
        & .btn{
            width: 160px;
            margin-bottom: 20px;
        }
    }
     
    & p{
        & a{
            text-decoration: none;
        }
    }
}









