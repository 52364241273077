/*--
/*  4.6 - Blog CSS
/*----------------------------------------*/


// Blog Wrapper 
.blog-wraper{
    padding-top: 30px;

    @media #{$large-mobile}{
        padding-top: 10px;
    }
}

// Blog Wrapper 2
.blog-wraper-2{
    padding-top: 20px;
}

// Single Blog 
.single-blog-item{
    margin-top: 30px;

    & .blog-image{
        & a{
            display: block;
            overflow: hidden;

            & img{
                transition: all 0.3s linear;
                width: 100%;
            }
        }
    }
    & .gallery-active{
        // Swiper Button Next & Prev
        & .swiper-button-next,
        & .swiper-button-prev{
            color: $white;
            transition: all 0.3s linear;

            &::after{
                font-size: 30px;               
            }

            &:hover{
                color: $primary;
            }
        }
    }
    & .blog-embed{
        & .ratio-16x9 {
            --aspect-ratio: calc(9.85 / 15 * 100%);
        }
        & .ratio-21x9 {
            --aspect-ratio: calc(11 / 21 * 100%);
        }
    } 
    & .blog-content{
        padding-top: 25px;

        & .post-title{
            & a{
                font-size: 18px;
                font-weight: 600;
                line-height: 1.3;
                color: #292929;
                text-transform: capitalize;
                position: relative;
                display: block;
                margin-bottom: 10px;   
                transition: all 0.3s linear;
                text-decoration: none;
                
                &:hover{
                    color: $primary;
                }
            }
        }
        & .blog-meta{
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            & > p{
                font-size: 14px;
                color: #9d9d9d;   
                margin-bottom: 0;

                & + p {
                    &::before {
                        content: "/";
                        display: inline-block;
                        margin: 0 7px;
                    }
                }

                & a{
                    text-decoration: none;
                    color: $primary;
                }
                &.meta-author{}
                &.meta-date{}
            }
        }
        & > p{
            font-size: 14px;
            font-weight: 400;
            display: inline-block;
            width: 100%;
            margin-top: 18px;
        }
       
        
    }

    &:hover{
        & .blog-image{
            & a{   
                & img{
                    transform: scale(1.1);
                }
            }
        }
    }
}

// Blog Active 
.blog-active{
    position: relative;

    & .swiper-button-prev,
    & .swiper-button-next{
        z-index: 15;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s linear;
        left: -30px;

        @media #{$laptop-device}{
            left: 0;
        }
        @media #{$small-mobile}{
            left: 0;
        }

        &::after{
            font-size: 30px;
            color: #e2e2e2;
            transition: all 0.3s linear;
        }

        &:hover{
            &::after{
                color: $primary;
            }
        }
    }

    & .swiper-button-next{
        left: auto;
        right: -30px;

        @media #{$laptop-device}{
            right: 0;
        }
        @media #{$small-mobile}{
            right: 0;
        }
    }

    &:hover{
        & .swiper-button-prev,
        & .swiper-button-next{
            opacity: 1;
            visibility: visible;
        }
    }
}


